angular.module('LeasePilot').directive('createConformedDeal', [
  '$rootScope',
  '$mdDialog',
  '$http',
  '$timeout',
  function($rootScope, $mdDialog, $http, $timeout) {
    return {
      restrict: 'E',
      templateUrl: 'create_conformed_deal.html',
      link: function($scope, element, attrs) {
        function create(replace) {
          let name = $scope.lease.customFields?.fileName || $scope.lease.tenantInfo?.tradeName || $scope.lease.tenantInfo?.name;

          $http({
            method: 'POST',
            url: '/api/leases/' + $scope.lease.id + '/create_conformed_deal',
            data: {
              lease_id: $scope.lease.id,
              name,
              new: true,
            },
          }).then(
            function(response) {
              location.href = '/leases';
            },
            function(error) {
              if (
                error.status == 422 &&
                error.data.error === 'conformed deal already exists'
              ) {
                confirmSaveAsConformedDeal();
              }
            },
          );
        }

        $scope.saveConformedDeal = function($event) {
          create(false);
        };

        function confirmSaveAsConformedDeal() {
          let name = $scope.lease.customFields?.fileName || $scope.lease.tenantInfo?.tradeName || $scope.lease.tenantInfo?.name;

          $mdDialog.show({
            controller:
              ('DialogController', ['$scope', '$mdDialog', DialogController]),
            template:
              '<div class="md-dialog-content confirm-modal">' +
              '<p class="title" >Confirm Save As Conformed Deal</p>' +
              '<p class="message">' +
              name +
              ' Lease Conformed Deal already exists. Do you want to override it?</p>' +
              '<div class="modal-actions">' +
              '<a class="btn blue" data-method="post" href="/api/leases/' +
              $scope.lease.id +
              '/create_conformed_deal">Yes</a>' +
              '<button class="btn blue-outline" ng-click="no()">No</button>' +
              '</div>' +
              '</div>',
            clickOutsideToClose: true,
            fullscreen: false,
          });
          function DialogController($scope, $mdDialog) {
            $scope.yes = function() {
              create(true);
              $mdDialog.cancel();
            };
            $scope.no = function() {
              $mdDialog.cancel();
            };
          }
        }
      },
    };
  },
]);
